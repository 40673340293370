<template>
    <UpdateAssitingLiving :id="$route.params.id" />
</template>
<script>
import UpdateAssitingLiving from "@/components/admin/modules/gest-assiting-living/UpdateAssitingLiving";

export default {
    name: "UpdateAssitingLivingView",
    title: "Actualizar Assisting Living | Baja California Health Tourism",
    components: {
        UpdateAssitingLiving
    }
}
</script>